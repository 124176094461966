import * as React from 'react';
import { ProjectLogo } from '../containers/MainLayout/Navigation/ProjectLogo';
import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';
import { FC } from 'react';
import { minWidth } from '../styles/helpers';

type Props = {
  message?: string;
  logo?: boolean;
};

export const LoadingScreen: FC<Props> = ({ message, logo = true }) => {
  return (
    <LoadingWrapper>
      {logo && <StyledLogo src="/challenger_kit_black.png" />}

      {message && <Typography>{message}</Typography>}

      <StyledCircularProgress color="primary" />
    </LoadingWrapper>
  );
};

const LoadingWrapper = styled.div`
  display: grid;
  grid-row-gap: 16px;
  place-items: center;
  place-content: center;
  height: 100vh;
`;

const StyledCircularProgress = styled(CircularProgress)`
  margin-top: 1rem;
`;

const StyledLogo = styled.img`
  width: 75%;

  ${minWidth.tablet} {
    width: 300px;
  }
`;
